<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="icon-container">
      <em :class="`icon-container__icon fal fa-${icon}`" />
    </div>
    <div class="indicator-container">
      <span class="indicator-container__title text-center">{{ title }}</span>
      <span class="indicator-container__count">{{ count }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusIndicatorIcon',
  props: {
    icon: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
    count: {
      type: Number,
      default: () => 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-container {
  background: transparent linear-gradient(0deg, #ffffff 0%, #fdf2e7 70%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  margin: 2px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  &__icon {
    font-size: 2.4rem;
    color: #ff8105;
  }
}
.indicator-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  &__title {
    color: #707070;
    font-size: 18px;
    font-weight: bold;
    height: 50px;
  }
  &__count {
    color: #404040;
    font-weight: bold;
    font-size: 28px;
  }
}
</style>
